<template>
  <div class="location-cloud">
    <div>
      <div class="tag-list tags">
        <router-link
          class="tag"
          v-for="location in tagLocations"
          :key="location.id"
          :to="{ name: 'location', params: { id: location.id } }"
          :class="{ 'is-large': location.rank === 100, 'is-medium': (location.rank >= 70 && location.rank < 100 ) }"
          >{{ location.location }}</router-link
        >
      </div>
      <p class="link-list">
        <router-link v-for="location in linkLocations" :key="location.id" :to="{ name: 'location', params: { id: location.id } }">{{
          location.location
        }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LocationCloud',
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      tagLocations() {
        return this.data.filter((e) => e.rank > 40);
      },
      linkLocations() {
        return this.data.filter((e) => e.rank <= 40);
      },
    },
  };
</script>

<style scoped></style>

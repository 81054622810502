<template>
  <div class="card">
    <div class="card-image">
      <figure class="image">
       <router-link :to="{ name: 'hotel', params: { id: data.id } }">  <img :src="randomPic()"/></router-link>
      </figure>
    </div>
    <div class="card-content">
      <div>
        <h2 class="title is-4"><router-link :to="{ name: 'hotel', params: { id: data.id } }">{{ data.hotelNameShort }}</router-link></h2>
        <p class="subtitle is-6">{{ data.city }}, {{ data.country }}</p>

        <dl class="rating">
          <dt>Review Score</dt>
          <dd>{{ data.hotelReviewScore }}</dd>
          <dt>Location</dt>
          <dd>{{ data.hotelLocationScore }}</dd>
          <dt>Cleanliness</dt>
          <dd>{{ data.hotelCleanlinessScore }}</dd>
          <dt>Value</dt>
          <dd>{{ data.hotelValueForMoneyScore }}</dd>
        </dl>
      </div>
      <div>
        <router-link class="button is-primary is-fullwidth" :to="{ name: 'hotel', params: { id: data.id } }">Find out more</router-link>
        <div class="separator"><span>or book on</span></div>
        <a v-if="data.agodaUrl" class="button is-agoda" :href="data.agodaUrl | agodaUrl">
          <span class="logo"><img src="../assets/agoda.svg" /></span>
        </a>
        <span v-else class="agoda-placeholder"></span>
        <a v-if="data.bookingUrl" class="button is-booking" :href="data.bookingUrl | bookingUrl">
          <span class="logo"> <img src="../assets/booking.svg" /></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HotelCard',
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    methods : {
    randomPic() {
    return require('../assets/room_'+Math.floor(Math.random() * 8)+".jpg");
}},
  };
  
</script>

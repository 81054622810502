import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import HomePage from '../views/HomePage.vue';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: HomePage,
  },
  {
    path: '/hotel/:id',
    name: 'hotel',
    component: () => import(/* webpackChunkName: "hotel" */ '../views/Hotel.vue'),
  },
  {
    path: '/location/:id',
    name: 'location',
    component: () => import(/* webpackChunkName: "location" */ '../views/Location.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/Cookies.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPolicy.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { bookingAffil, agodaAffil } from '@/const';

import './scss/hoteldetail.scss';

Vue.config.productionTip = false;

Vue.filter('agodaUrl', (value) => {
  if (agodaAffil) {
    let url = new URL(value);
    url.searchParams.set('cid', agodaAffil);
    return url.toString();
  }
  return value;
});

Vue.filter('bookingUrl', (value) => {
  if (bookingAffil) {
    let url = new URL(value);
    url.searchParams.set('aid', bookingAffil);
    return url.toString();
  }
  return value;
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

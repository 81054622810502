<template>
  <header class="header" :class="{ 'is-short': short, 'no-search': !search }">
    <div class="container">
      <div class="header__content">
        <div>
          <router-link to="/"
            ><h1>{{ appName }}</h1></router-link
          >
        </div>

        <nav v-if="hasCrumbs" class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li v-for="(link, index) in breadcrumbs" :class="{ 'is-active': index === breadcrumbs.length - 1 }">
              <template v-if="!link.url && link.type">
                <a @click="resolveCrumb(link.type, link.name)">{{ link.name }}</a>
              </template>
              <template v-else-if="!link.url">
                <a>{{ link.name }}</a>
              </template>
              <template v-else>
                <router-link :to="link.url">{{ link.name }}</router-link>
              </template>
            </li>
          </ul>
        </nav>

        <div v-if="search">
          <h2 class="title is-1">{{ title }}</h2>
          <h3 class="subtitle is-5">Find the best place to stay. Get inspired by other’s choices.</h3>

          <Search />
        </div>
        <div v-else-if="!short">
          <h2 class="title is-1">{{ title }}</h2>
          <h3 class="subtitle is-5">Find the best place to stay. Get inspired by other’s choices.</h3>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import Search from '@/components/Search';
  import { appName } from '@/const';
  import { getLocationsWhere } from '@/firebase';

  export default {
    name: 'Header',
    components: { Search },
    props: {
      search: {
        type: Boolean,
        default: false,
      },
      short: {
        type: Boolean,
        default: false,
      },
      breadcrumbs: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: 'Where to go next',
      },
    },
    data() {
      return {
        appName: appName,
      };
    },
    computed: {
      hasCrumbs() {
        return this.breadcrumbs.length > 0;
      },
    },
    methods: {
      async resolveCrumb(type, name) {
        // console.log(type, name);
        const loc = await getLocationsWhere(type, name);
        if (loc && loc.length > 0) {
          this.$router.push({ name: 'location', params: { id: loc[0].id } });
        }
      },
    },
  };
</script>

<template>
  <div>
    <Header search />
    <section class="section">
      <div class="container">
        <h3 class="title is-3">Get inspired by popular destinations</h3>
        <span class="subtitle is-6">(based on the number of reservations in the past 30 days)</span>

        <LocationCloud :data="locations" />
      </div>
    </section>
    <section class="section color-bg">
      <div class="container">
        <h3 class="title is-3">Experience most popular places to stay</h3>
        <span class="subtitle is-6">(based on the number of reservations in the past 30 days)</span>

        <div class="hotel-cards">
          <HotelCard v-for="(hotel, index) in hotels" :data="hotel" :key="index" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import HotelCard from '@/components/HotelCard';
  import Header from '@/components/Header';
  import { getTopHotels, getTopLocations } from '@/firebase';
  import LocationCloud from '@/components/LocationCloud'
  import { titleHomepage } from "@/const";

  export default {
    name: 'HomePage',
    components: { LocationCloud, Header, HotelCard },
    data() {
      return {
        hotels: [],
        locations: [],
      };
    },
    metaInfo() {
      return {
        title: titleHomepage,
      };
    },
    async mounted() {
      this.locations = await getTopLocations('hp');
      this.locations.sort((a, b) => b.rank - a.rank);
      this.hotels = await getTopHotels();
    },
  };
</script>

<template>
  <footer class="footer">
    <div class="footer__content container level">
      <div class="level-left">© {{ appName }} 2022. All rights reserved.</div>
      <div class="level-right">
        <ul>
          <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
          <li><router-link to="/cookies">Cookies</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
  import { appName } from '@/const';
  export default {
    name: 'Footer',
     data() {
      return {
        appName: appName,
      };
    }
  };
</script>

<template>
  <div v-click-outside="reset" class="search-field field has-addons">
    <div class="control is-fullwidth">
      <input
        v-model="search"
        class="input"
        type="text"
        placeholder="Start typing country or city name..."
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
      />
    </div>
    <div class="control">
      <a class="button is-primary">
        <span class="is-hidden-desktop"><img src="../assets/search.svg" /></span>
        <span class="is-hidden-touch"> Search </span>
      </a>
    </div>
    <div v-if="suggestions.length > 0" class="search-field__suggestions">
      <ul>
        <li v-for="(location, i) in suggestions" :key="location.id">
          <router-link :to="{ name: 'location', params: { id: location.id } }" :class="{ 'is-active': i === arrowCounter }">
            <strong>{{ location.location }}</strong>
            <span>{{ location.parent }}</span>
            <span v-if="location.parentCountry != undefined">, {{ location.parentCountry }}</span>
            <i class="tag">{{ fixType(location.type) }}</i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { getLocationSuggesstion } from '@/firebase';
  import ClickOutside from 'vue-click-outside';

  const debounce = (fn, delay) => {
    var timeoutID = null;
    return function () {
      clearTimeout(timeoutID);
      var args = arguments;
      var that = this;
      timeoutID = setTimeout(function () {
        fn.apply(that, args);
      }, delay);
    };
  };

  export default {
    name: 'Search',
    data() {
      return {
        search: '',
        debouncedSearch: '',
        suggestions: [],
        arrowCounter: -1,
      };
    },
    directives: {
      ClickOutside,
    },
    watch: {
      search: debounce(function (newVal) {
        this.debouncedSearch = newVal.toLowerCase();
      }, 500),
      debouncedSearch() {
        this.doSearch();
      },
    },
    methods: {
      async doSearch() {
        if (this.debouncedSearch.length > 1) {
          this.suggestions = await getLocationSuggesstion(this.debouncedSearch);
          this.arrowCounter = -1;
        }
      },
      onArrowDown() {
        if (this.arrowCounter < this.suggestions.length) {
          this.arrowCounter = this.arrowCounter + 1;
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        }
      },
      onEnter() {
        this.$router.push({ name: 'location', params: { id: this.suggestions[this.arrowCounter].id } });
        this.reset();
      },
      reset() {
        this.arrowCounter = -1;
        this.suggestions = [];
      },
      fixType(type) {
        if (type === 'Area') return 'Region';
        return type;
      }
    },
  };
</script>

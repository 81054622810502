<template>
  <div id="app">
    <router-view />
    <Footer />
  </div>
</template>

<script>
  import Footer from '@/components/Footer';
  import { titleTemplate, metaDescription } from '@/const';

  export default {
    components: { Footer },
    metaInfo: {
      titleTemplate,
      meta: [{ name: 'description', content: metaDescription }],
    },
  };
</script>
